<template>
   <section class="styry h-100">
      <div class="h-100">
         <div class="grid-margin h-100">
            <div class="h-100 container">
                
                <div class='h-100 w-100 row mt-5'  v-if='$store.state.system[cdata.system._route_].is_all_fin_for_this_page'>
                    <div class="w-100">
                      <b-carousel
                        id="carousel-fade"
                        style="text-shadow: 0px 0px 2px #000"
                        slide
                        img-width="1024"
                        img-height="480"
                        controls
                      >
                        <b-carousel-slide
                          caption="First Slide"
                          img-src="https://picsum.photos/1024/480/?image=10"
                        ></b-carousel-slide>
                        <b-carousel-slide
                          caption="Second Slide"
                          img-src="https://picsum.photos/1024/480/?image=12"
                        ></b-carousel-slide>
                        <b-carousel-slide
                          caption="Third Slide"
                          img-src="https://picsum.photos/1024/480/?image=22"
                        ></b-carousel-slide>
                      </b-carousel>
                    </div>
                </div>

            </div>
         </div>
      </div>
   </section>
</template>

<script>
    import Vue from 'vue'
    import { mapGetters } from 'vuex'
    
            var cdata = {//Если не указать - не всключиться реактивность 
                /* COPYPASTE MODIFY */
                system: {
                    _route_: "g1_story", /* MODIFY */
                    is_section: {// store/index  -> is_required_sections
                        center: false, /* СЕКЦИИ НА СТРАНИЦЕ MODIFY  v-if='cdata.system.is_section.center'   */
                    },
                    is_stage: {// store/index  -> is_required_stages
                        center: false,
                    },
                    cnf: {},
                    buttons: {
                        common: {
                            is_button_blocked: true,
                            button_blocking_exeption_list: {}, //ДЛЯ ДЕФОЛТНЫХ КНОПОК!!!
                        },
                        common_without_symmary: {
                            is_button_blocked: true,
                            button_blocking_exeption_list:
                                    {
                                        //0: "r_auth", //r_auth  
                                        0: "json_version", //r_auth 
                                        1: "events_list", //r_auth 
                                        //2: "json_layout_xxxx__pos_xx", НЕТ, просто не добавлять на эту страницу динамические таблицы!!!
                                    }
                        },
                        button1: {
                            is_button_blocked: true,
                            button_blocking_exeption_list:
                                    {
                                        0: "r_auth", //r_auth
                                    }
                        },
                    },
                },
                errors: {
                    is_errors: false,
                    actions_error: {},
                },
                business_logic: {
                },
            }

    export default {
        name: 'story', /* MODIFY */
        data() {
            return {
                cdata: cdata,
            };
        },
        created() { },
        mounted() {
            document.getElementById('await_loader_outer').style.display = 'block';
            this.$store.dispatch('_system/vuex_set__route_', this.cdata.system._route_);
            this.$root._PAGE().on_mounted(this.$store, this.cdata); //обнулить флажки

            //IF NEEDED 

            setTimeout(() => {
                this.$store.dispatch('_system/vuex_set__is_mounted_execute', {'is_mounted_execute': true, '_route_': this.cdata.system._route_ });
                this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
            }, 300);
            //this.cdata.system.is_mounted_execute = true;    
            //this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
            
            
//            REMOVE???!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json___global__level_settings__back(this.$store, this.cdata));
            this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json___level_props__exp_by_tasks__back(this.$store, this.cdata));
            this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json___level_props__solve_limit__back(this.$store, this.cdata));
            
        },
        computed: {

            /* COPYPASTE */
            ...mapGetters(['__blocked_tables', '__is_required_sections', '__is_required_stages', '__tables', '__is_errors']),
            _system() {
                let debug = {};
                try {
                    debug['tables'] = this.__tables(this.cdata['system']['_route_']);
                    debug['is_errors'] = this.__is_errors(this.cdata['system']['_route_']);
                    debug['blockings'] = this.__blocked_tables(this.cdata['system']['_route_']);
                    debug['sections'] = this.__is_required_sections(this.cdata['system']['_route_']);
                    debug['stages'] = this.__is_required_stages(this.cdata['system']['_route_']);
                } catch (ex) {
                    console.log(ex);
                }
                return debug;
            },
            /* MODIFY */
            state() {
                let state = {};
                try {
                    /* MODIFY */ //ВЫБРАТЬ используемые МОДУЛИ --> подгрузятся ТАБЛИЦЫ в них
                    state['auth'] = this.$store.state['state_tables'].state_auth; /* MODIFY */

                } catch (ex) {
                    console.log(ex);
                }

                return state;
            },
            form() {
                let form = {}; // form.classnum


                form['cnt_loaded'] = this.$store.state.cnt_loaded;
                form['is_all_stage_fin'] = this.$store.state.is_all_stage_fin;
                form['classnum'] = this.$store.state.state_tables.state_auth.r_auth.hasOwnProperty('classnum') ? this.$store.state.state_tables.state_auth.r_auth['classnum'] : "-1";
                form['lid'] = this.$store.state.state_tables.state_auth.r_auth.hasOwnProperty('lid') ? this.$store.state.state_tables.state_auth.r_auth['lid'] : "-1";
                return form;
            },
            /* #ODIFY */
            /* #OPYPASTE */

        },
        watch: {

            /* COPYPASTE на каждое изменение при работе с таблицей два вызова на начало и завершение*/
            "form.cnt_loaded": {
                handler: function () {



                    //ПОСТОЯННО ВЫПОЛНЯЕТСЯ после выполнения всех стадий, на любое действие с таблицами
                    if (this.$store.state.is_all_stage_fin)
                    {
                        document.getElementById('await_loader_outer').style.display = 'none';
                        //console.log("EXECUTE AFTER ALL STAGE LOAD FIN [" + this.cdata.system._route_ + "]");
                    }


                    //ВЫПОЛНИТСЯ РАЗОВО - проверяем готовы ли стадии, для текущей страницы 
                    if (this.$store.state.system[this.cdata.system._route_].is_mounted_execute && !this.$store.state.system[cdata.system._route_].is_all_fin_for_this_page) {
                        //проверить завершение всех стадий для страницы (срабатывал раньше времени)
                        //set cdata.system.is_stages_fin_for_THIS_page
                        // console.log("CALC is_stages_fin_for_THIS_page");
                        if (this.$root._SYS().check_is_stages_fin_for_this_page(this.$store, this.cdata))
                        {
                            this.$store.dispatch('_system/vuex_set__is_all_fin_for_this_page',   {'is_all_fin_for_this_page': true, '_route_': this.cdata.system._route_ });
                            console.log("REGISTER ALL STAGE LOAD FIN - FOR PAGE [" + this.cdata.system._route_ + "]");
                        }


                    }


                    //ПОСТОЯННО ВЫПОЛНЯЕТСЯ после загрузки всех стадий для страницы, на любое действие с таблицами
                    if (this.$store.state.system[cdata.system._route_].is_all_fin_for_this_page)
                    {
                        //просто постоянно перещитываем счетчики и блокровки кнопок!
                        this.$root._SYS().watch_force_called_on_mounted_execute(this.$store, this.cdata, true);
                    }



                },
                deep: true, //чтобы детектил изменения вложенных в объекто элементов
                immediate: true, //При передаче immediate: true в опциях, коллбэк будет вызываться незамедлительно с текущим значением отслеживаемого выражения:
            },
        },
        methods: {
            
        },
        beforeDestroy() {
        }


    }
</script>

<style lang="scss" scoped>

</style>

